<template>
  <div>
    <h2 class="mb-4">Kunden Import</h2>

    <div class="mb-4">
      <button
        class="text-white bg-primary-700 hover:bg-primary-800 rounded px-4 py-2 text-center mr-2"
        @click="insertData"
      >
        Kunden importieren
      </button>

      <button
        class="text-white bg-primary-700 hover:bg-primary-800 rounded px-4 py-2 text-center mr-2"
        @click="updateData"
      >
        Update Kunden Tabelle
      </button>
    </div>

    <dashboard-element>
      <dashboard-head title="Kunden" link="/kunden" />
      <dashboard-table class="p-3">
        <dashboard-card
          v-for="kunde in customers"
          :key="kunde.customernumber"
          :title="`${kunde.company}`"
          :content="`${kunde.customernumber}`"
          :link="`/kunde/${kunde.customernumber}`"
        />
      </dashboard-table>
    </dashboard-element>
  </div>
</template>

<script>
import gql from "graphql-tag";
import DashboardElement from "@/components/dashboard/DashboardElement.vue";
import DashboardHead from "@/components/dashboard/DashboardHead.vue";
import DashboardCard from "@/components/dashboard/DashboardCard.vue";
import DashboardTable from "@/components/dashboard/DashboardTable.vue";
import { supabase } from "../../plugins/supabase";

export const GET_MY_DATA = gql`
  query Kunden {
    Kunden {
      KundenNr
      Firma
    }
  }
`;

export default {
  components: {
    DashboardElement,
    DashboardHead,
    DashboardCard,
    DashboardTable
  },
  data() {
    return {
      customers: []
    };
  },
  apollo: {
    Kunden: {
      query: GET_MY_DATA,
      error(error) {
        this.error = JSON.stringify(error.message);
      }
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    async updateData() {
      this.Kunden.map(kunde =>
        supabase.from("kunden").upsert([
          {
            customernumber: kunde.KundenNr,
            company: kunde.Firma
          }
        ])
      );
    },
    async insertData() {
      const neue_kunden = [];
      this.Kunden.map(kunde =>
        neue_kunden.push({
          customernumber: kunde.KundenNr,
          company: kunde.Firma
        })
      );

      await supabase.from("kunden").insert(neue_kunden);
    },
    async loadData() {
      let { data: kunden } = await supabase
        .from("kunden")
        .select("*")
        .range(0, 9);

      this.customers = kunden;
    }
  }
};
</script>
