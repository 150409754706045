<template>
  <div>
    <h2 class="mb-4">Artikel Import</h2>

    <div class="mb-4">
      <button
        class="text-white bg-primary-700 hover:bg-primary-800 rounded px-4 py-2 text-center mr-2"
        @click="insertData"
      >
        Artikel importieren
      </button>

      <button
        class="text-white bg-primary-700 hover:bg-primary-800 rounded px-4 py-2 text-center mr-2"
        @click="updateData"
      >
        Update Artikel Tabelle
      </button>
    </div>

    <div v-if="$apollo.queries.Artikel.loading">
      Lade Daten...
    </div>

    <dashboard-element>
      <dashboard-head title="Artikel" link="/artikel" />
      <dashboard-table class="p-3">
        <dashboard-card
          v-for="at in products"
          :key="at.artikelcode"
          :title="`${at.artikelcode}`"
          :content="`${at.bezeichnung}`"
          :link="`/artikel/${at.artikelcode}`"
          :date="at.created_at"
        />
      </dashboard-table>
    </dashboard-element>
  </div>
</template>

<script>
import gql from "graphql-tag";
import DashboardElement from "@/components/dashboard/DashboardElement.vue";
import DashboardHead from "@/components/dashboard/DashboardHead.vue";
import DashboardCard from "@/components/dashboard/DashboardCard.vue";
import DashboardTable from "@/components/dashboard/DashboardTable.vue";
import { supabase } from "../../plugins/supabase";

export const GET_MY_AG = gql`
  query ArtikelArbeitsgaenge {
    artikel_arbeitsga_nge(where: { Arbeitsgang: { _nlike: "" } }) {
      Arbeitsgang
      Artikelcode
    }
  }
`;

export const GET_MY_DATA = gql`
  query Artikel {
    Artikel {
      Artikelcode
      Artikelgruppe
      Belagbreite
      Belagh_he
      Bemerkungen
      Bindung
      Bindungsdichte
      Bezeichnung
      Bohrung
      Dichte
      Coating
      Durchmesser
      Formel
      FormCode
      Formwerkzeug
      Gesamth_he
      H_he
      K_rnung
      Karat
      Konzentration
      Korndichte
      Korngr_e
      Korntyp
      KundenNr
      Langtext
      PreisKalkulation
      Rohling
      Rohlingh_he
      Verkaufspreis
      Winkel
      Werkstoff
      ZeichnungsNr
      Typ
    }
  }
`;

export default {
  components: {
    DashboardElement,
    DashboardHead,
    DashboardCard,
    DashboardTable
  },
  apollo: {
    Artikel: {
      query: GET_MY_DATA,
      error(error) {
        this.error = JSON.stringify(error.message);
      }
    }
  },
  computed: {
    products() {
      return this.$store.getters.getAllProducts;
    }
  },
  methods: {
    parseNumber(str) {
      return parseFloat(str.replace(",", "."));
    },
    async updateData() {
      this.Artikel.map(kunde =>
        supabase.from("artikel").upsert([
          {
            customernumber: kunde.KundenNr,
            company: kunde.Firma
          }
        ])
      );
    },
    async insertData() {
      const neue_artikel = [];
      this.Artikel.map(pa =>
        neue_artikel.push({
          artikelcode: pa.Artikelcode,
          artikelgruppe: pa.Artikelgruppe,
          belagbreite: this.parseNumber(pa.Belagbreite),
          belaghoehe: this.parseNumber(pa.Belagh_he),
          bemerkungen: pa.Bemerkungen,
          bindung: pa.Bindung,
          bindungsdichte: this.parseNumber(pa.Bindungsdichte),
          bezeichnung: pa.Bezeichnung,
          bohrung: pa.Bohrung,
          dichte: this.parseNumber(pa.Dichte),
          coating: pa.Coating,
          durchmesser: this.parseNumber(pa.Durchmesser),
          formel: pa.Formel,
          formcode: pa.Formcode,
          formwerkzeug: pa.Formwerkzeug,
          gesamthoehe: this.parseNumber(pa.Gesamth_he),
          hoehe: this.parseNumber(pa.H_he),
          koernung: pa.K_rnung,
          karat: pa.Karat,
          konzentration: pa.Konzentration,
          korndichte: this.parseNumber(pa.Korndichte),
          korngroesse: pa.Korngr_e,
          korntyp: pa.Korntyp,
          kundennr: parseInt(pa.KundenNr),
          langtext: pa.Lantext,
          rohling: pa.Rohling,
          rohlingshoehe: this.parseNumber(pa.Rohlingh_he),
          verkaufspreis: this.parseNumber(pa.Verkaufspreis),
          winkel: this.parseNumber(pa.Winkel),
          werkstoff: pa.Werkstoff,
          zeichnungsnr: pa.ZeichnungsNr,
          typ: parseInt(pa.Typ)
        })
      );

      await supabase.from("artikel").insert(neue_artikel);
    }
  }
};
</script>
